<footer>

  <div class="sections container">
    @for (section of $navConfig()?.sections; track $index) {
       @if($index == 0 || $index == 2){
      <div [innerHTML]="section | sanitize"></div>
       }@else{
        <div class="nav-wrapper container">
          <sps-social-links/>
          <nav>
            @for (link of $navConfig()?.links; track link.href) {
      
              @if (link.label === 'Cookie Setup') {
                <button (click)="showCookieDialog()">{{ link.label }}</button>
              } @else {
                <sps-link [icon]="false" [href]="link.href" (click)="onClick(link.onClick)">
                  {{ link.label }}
                </sps-link>
              }
            }
          </nav>
          <div id="copyright">&copy; {{ currentYear }} SPS. All Rights Reserved</div>
        </div>
       }
    }
  </div>





  @if (versionInfo) {
    <div id="version-info">
      v{{ versionInfo.version }} | {{ versionInfo.date | date: 'YYYY/MM/dd HH:mm:ss' }}
    </div>
  }
</footer>
